"use client";

import Link from "next/link";
import React, { useState, useEffect } from "react";
// import image from "/public/assets/34/34_alpha.png";
import Image from "next/image";

export default function DesktopDropDownMenu(props) {
  const navigationData = props.data;
  const dropDownLinks = props.dropDownLinks;
  const selectedLink = props.selectedLink;
  const [selectedSubLink, setSelectedSublink] = useState(
    "Walk Behind Floor Scrubbers"
  );
  const machines = props.machines;
  const rightColumnStyle = navigationData.link.reduce((acc, link) => {
    if (
      link.name === selectedLink &&
      link.background_image &&
      link.background_image.data &&
      link.background_image.data.attributes
    ) {
      acc.backgroundImage = `url(${link.background_image.data.attributes.url})`;
    }
    return acc;
  }, {});
  const selectedLinkDetails = navigationData.link.find(
    (link) => link.name === selectedLink
  );

  const rightColumnTitle = selectedLinkDetails?.title || "Default Title";
  const rightColumnDescription = selectedLinkDetails?.description || "Default";

  useEffect(() => {
    const handleClick = () => {
      alert("Calling!");
    };

    const telLink = document.querySelector('a[href^="tel:"]');
    telLink.addEventListener("click", handleClick);

    // Cleanup function
    return () => {
      telLink.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="content-wrapper">
      <div className="left-column">
        <ul>
          <li className="title">{selectedLink}</li>
          {dropDownLinks?.length > 0 && selectedLink === "Products"
            ? dropDownLinks.map((link) => (
                <li
                  key={link.id}
                  onClick={() => {
                    setSelectedSublink(link.link);
                  }}
                  // onMouseEnter={() => setSelectedSublink(link.link)}
                >
                  <Link href="javascript:void(0)">{link.link}</Link>
                </li>
              ))
            : dropDownLinks?.map((link) => (
                <li key={link.id} onClick={props.close}>
                  <Link href={link.location}>{link.link}</Link>
                </li>
              ))}
        </ul>
      </div>
      {selectedLink === "Products" ? (
        <div className="right-column">
          <h2>{selectedSubLink}</h2>
          <div className="machines-wrapper">
            {machines.map((machine) => {
              if (
                machine.attributes.style.data.attributes.style ===
                selectedSubLink
              ) {
                return (
                  <Link
                    href={`/models/${machine.attributes.slug}`}
                    onClick={props.close}
                    key={machine.attributes.id}
                  >
                    <div className="machine">
                      <h3>{machine.attributes.name}</h3>
                      <Image
                        src={machine.attributes.main_image.data.attributes.url}
                        width={1920}
                        height={1080}
                        alt={
                          machine.attributes.main_image.data.attributes
                            .alternativeText ||
                          `FactoryCat ${machine.attributes.name} || ${machine.attributes.style.data.attributes.style}`
                        }
                      />
                    </div>
                  </Link>
                );
              }
            })}
          </div>
        </div>
      ) : (
        // set the div background style based on the selectedlink
        <div
          className="no-products"
          style={{ backgroundImage: rightColumnStyle.backgroundImage }}
        >
          <span className="right-column-title">{rightColumnTitle}</span>
          <span className="right-column-description">
            {rightColumnDescription}
          </span>
        </div>
      )}
    </div>
  );
}
